<template>
    <div>
        <div class="header">
            <van-icon name="arrow-left" @click="$router.push({path: '/self/settings'})"/>
            <div>数据</div>
        </div>
        <div>个人信息</div>
        <van-form @submit="onSubmit">
            <van-field
                v-model="personalMsg.username"
                name="username"
                label="姓名"
                placeholder="姓名"
            />
            <van-field
                v-model="personalMsg.nickname"
                name="nickname"
                label="昵称"
                placeholder="昵称"
            />
            <van-field
                v-model="personalMsg.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
             <van-uploader :after-read="afterRead" />
            <van-field
                v-model="personalMsg.wxcode"
                name="wxcode"
                label="微信号"
                placeholder="微信号"
            />
             <van-field
                v-model="personalMsg.phone"
                name="phone"
                label="手机"
                placeholder="手机"
            />
            <van-field
                v-model="personalMsg.email"
                name="email"
                label="邮箱"
                placeholder="邮箱"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">更改</van-button>
            </div>
        </van-form>
        <div>收款人信息</div>
        <van-form @submit="onSubmitPayInfo">
            <van-field
                v-model="payInfobak.username"
                name="username"
                label="姓名"
                placeholder="姓名"
            />
            <van-field
                v-model="payInfobak.nickname"
                name="nickname"
                label="昵称"
                placeholder="昵称"
            />
                    <van-uploader :after-read="afterReada" />
            <van-field
                v-model="payInfobak.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
     
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">更改</van-button>
            </div>
        </van-form>
        <div>零钱/零钱通</div>
        <van-form @submit="onSubmitFund">
            <van-field
                v-model="fundData.coin"
                name="coin"
                label="零钱"
                placeholder="零钱"
            />
            <van-field
                v-model="fundData.coinCash"
                name="coinCash"
                label="零钱通"
                placeholder="零钱通"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">更改</van-button>
            </div>
        </van-form>
        <div>银行卡</div>
        <div v-for="bank in bankList" :key="bank.id" class="bankcon">
            <div>{{bank.title}}{{bank.type}}-{{ bank.no }}</div>
            <van-icon name="cross" @click="delBank(bank.id)" />
        </div>
        <div>添加银行卡</div>
        <van-form @submit="onSubmitNewBank">
            <van-field name="radio" label="卡类型">
                <template #input>
                    <van-radio-group v-model="newBankId" direction="horizontal">
                        <van-radio :name="bank.id" v-for="bank in bankDefaultList" :key="bank.id">{{ bank.title }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="newBankNo"
                name="newBankNo"
                label="卡号"
                placeholder="卡号"
            />
            <van-field name="isout" label="是否超额">
                <template #input>
                    <van-radio-group v-model="isOut" direction="horizontal">
                        <van-radio :name="true">是</van-radio>
                        <van-radio :name="false">否</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">添加</van-button>
            </div>
        </van-form>
        <div>账单</div>
        <div v-for="bill in billList" :key="bill.id" class="bill">
            <div>{{ bill.text }}</div>
            <div class="value">{{ bill.value }}</div>
            <van-icon name="cross" @click="delBill(bill.id)" />
        </div>
        <div>添加账单</div>
        <van-form @submit="onSubmitNewBill">
            <van-field
                v-model="bill.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
                        <van-uploader :after-read="afterReadb" />
            <van-field
                v-model="bill.text"
                name="avatar"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="bill.value"
                name="avatar"
                label="值"
                placeholder="值"
            />
            <van-field
                v-model="bill.transferTime"
                name="avatar"
                label="转账时间"
                 @click="showDatePicker = true"
                placeholder="转账时间"
            />
            <van-field
                v-model="bill.incomeTime"
                name="avatar"
                label="收款时间"
                 @click="showTimePicker = true"
                placeholder="收款时间"
            />
  	<van-popup v-model:show="showDatePicker" round position="bottom">          
<van-datetime-picker
  v-model="currentDate"
  type="datetime"
  title="选择年月日"
  @confirm="showDatePicker = false"
  @cancel="showDatePicker = false" 
  :min-date="minDate"
  :max-date="maxDate"
  @change="dateChange"
/>

	</van-popup> 
	<van-popup v-model:show="showTimePicker" round position="bottom">          
<van-datetime-picker
  v-model="currentTime"
  type="datetime"
  title="选择年月日"
  @confirm="showTimePicker = false"
  @cancel="showTimePicker = false" 
  :min-date="minDate"
  :max-date="maxDate"
  @change="timeChange"
/>

	</van-popup>
            <van-field name="radio" label="是否零钱">
                <template #input>
                    <van-radio-group v-model="bill.type" direction="horizontal">
                        <van-radio name="1">是</van-radio>
                        <van-radio name="2">否</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field name="radio" label="银行卡" v-if="bill.type == 2">
                <template #input>
                    <van-radio-group v-model="bill.bankId">
                        <van-radio :name="bank.id" v-for="bank in bankList" :key="bank.id">{{ bank.title }}{{ bank.no | banknofilter }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="bill.number"
                name="avatar"
                label="转账单号"
                placeholder="转账单号"
                @click="suiji"
            />
            <van-field
                v-model="bill.balance"
                name="avatar"
                label="零钱余额"
                placeholder="零钱余额"
            />
            <van-field name="zrtype" label="转入转出">
                <template #input>
                    <van-radio-group v-model="bill.zrtype" direction="horizontal">
                        <van-radio name="1">转出</van-radio>
                        <van-radio name="2">转入</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
           
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">添加</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Dialog } from 'vant';
import { DatePicker } from 'vant';
    export default {
        data() {
            return {
                showDatePicker: false,
                showTimePicker: false,
                      randomNumber: null,
                personalMsg: {
                    username: '',
                    nickname: '',
                    avatar: '',
                    wxcode: '',
                    phone: '',
                    email: ''
                },
                payInfobak: {
                    username: '',
                    nickname: '',
                    avatar: ''
                },
                fundData: {
                    coin: '',
                    coinCash: ''
                },
                newBankId: '',
                newBankNo: '',
                isOut: false,
                bill: {
                    avatar: '',
                    text: '',
                    value: '',
                    transferTime: '',
                    incomeTime: '',
                    type: '',
                    bankId: '',
                    number: '',
                    balance: '',
                    zrtype: ''
                },
              minDate: new Date(2020, 0, 1),
              maxDate: new Date(2025, 10, 1),
              currentDate: new Date(2021, 0, 17),
              currentTime: new Date(2021, 0, 17),
            };
        },
        filters: {
            banknofilter(no) {
                const arr = no.split(',')
                return arr[arr.length-1]
            }
            
            
        },
        computed: {
            ...mapState({
                personal: state => state.userInfo,
                payInfo: state => state.payInfo,
                fund: state => state.fund,
                bankDefaultList: state => state.bankDefaultList,
                billList: state => state.billList
            }),
            ...mapGetters([
                'bankList'
            ])
        },
        mounted() {
            this.personalMsg = this.personal
            this.payInfobak = this.payInfo
            this.fundData = this.fund

 
            
            
        },
        methods: {
            ...mapMutations([
                'setUserInfo',
                'setPayInfo',
                'setFund',
                'setBankList',
                'addBill',
                'delBillList'
            ]),
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.personalMsg.avatar = file.content;
               console.log(file);
             },
             generateRandomNumber() {
                  function getRandomDigit() {
                    return Math.floor(Math.random() * 10);
                  }
            
                  let randomNumberStr = "";
                  for (let i = 0; i < 33; i++) {
                    randomNumberStr += getRandomDigit();
                  }
                  return randomNumberStr;
                },
             dateChange() {
                 
                  if(this.currentDate){
                     var date = this.currentDate
                     let month = date.getMonth() + 1;// 当前月份
                     let year = date.getFullYear(); // 获取当前年份
                     let day = date.getDate(); // 获取当前日期
                     let hours = date.getHours(); // 获取当前小时
                     let minutes = date.getMinutes(); // 获取当前分钟
                     let getSeconds = date.getSeconds(); // 获取当前分钟
                     console.log( year+'年'+month+'月'+day+'日' + " "+hours +":"+minutes+":"+getSeconds)
    	                this.bill.transferTime =  year+'年'+month+'月'+day+'日' + " "+hours +":"+minutes+":"+getSeconds
                  }
             },             timeChange() {
                 
                  if(this.currentTime){
                     var date = this.currentTime
                     let month = date.getMonth() + 1;// 当前月份
                     let year = date.getFullYear(); // 获取当前年份
                     let day = date.getDate(); // 获取当前日期
                     let hours = date.getHours(); // 获取当前小时
                     let minutes = date.getMinutes(); // 获取当前分钟
                     let getSeconds = date.getSeconds(); // 获取当前分钟
                     console.log( year+'年'+month+'月'+day+'日' + " "+hours +":"+minutes+":"+getSeconds)
    	                this.bill.incomeTime =  year+'年'+month+'月'+day+'日' + " "+hours +":"+minutes+":"+getSeconds
                  }
             },
             afterReada(file) {
                // 此时可以自行将文件上传至服务器
                this.payInfobak.avatar = file.content;
               console.log(file);
             },
             afterReadb(file) {
                // 此时可以自行将文件上传至服务器
                this.bill.avatar = file.content;
               console.log(file);
             },
             suiji(){
                  // 生成一个介于0到1之间的随机数
               
                 this.bill.number = this.generateRandomNumber();
             },
            onSubmit() {
                this.setUserInfo(this.personal)
                Dialog.alert({
                    message: '个人信息更新',
                })

            },
            onSubmitPayInfo() {
                this.setPayInfo(this.payInfobak)
                Dialog.alert({
                    message: '付款信息更新',
                })
            },
            onSubmitFund() {
                this.setFund(this.fundData)
                Dialog.alert({
                    message: '零钱/零钱通更新',
                })
            },
            delBank(id) {
                const bankList = JSON.parse(JSON.stringify(this.bankList))
                const newBankList = bankList.filter(bank => bank.id != id)
                this.setBankList(newBankList)
                Dialog.alert({
                    message: '删除银行卡成功',
                })
            },
            onSubmitNewBank() {
                const bankDefaultList = JSON.parse(JSON.stringify(this.bankDefaultList))
                const bankList = JSON.parse(JSON.stringify(this.bankList))
                const newBank = bankDefaultList.filter(bank => bank.id == this.newBankId)[0]
                let newBankNo = ''
                for(let i = 0;i<16;i++) {
                    if (i!=0 && i%4==0) {
                        newBankNo+=`,${this.newBankNo[i]}`
                    } else {
                        newBankNo+=`${this.newBankNo[i]}`
                    }
                }
                this.setBankList([...bankList, {...newBank, no: newBankNo, isOut: this.isOut, id: (new Date()).getTime() }])
                Dialog.alert({
                    message: '添加银行卡成功',
                })
            },
            delBill(id) {
                this.delBillList(id)
            },
            onSubmitNewBill() {
                const id = new Date().getTime()
                const newBill = {...this.bill}
                console.log(newBill)
                newBill.id = id
                if (this.bill.type == 1) {
                    newBill.type = '零钱'
                }
                if (this.bill.type == 2) {
                    const bank = this.bankList.filter(bill => bill.id == this.bill.bankId)[0]
                    if (bank.no) {

                        const arr = bank.no.split(',')
                        newBill.type = `${bank.title}（${arr[arr.length-1]}）`
                    }
                }
                this.addBill(newBill)
            }
        },
    };
</script>
<style lang="less" scoped>
.header {
    position: relative;
    z-index: 99;
    // overflow: hidden;
    height: 45px;
    display: flex;
    align-items: center;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #1b1b1b;
    opacity: 1;
    color: #fff;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    div {
        flex: 1;
        margin-left: -17px;
    }
}
.bankcon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
}
.bill {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    .value {
        flex: 1;
        text-align: right;
        margin-right: 20px;
    }
}
</style>